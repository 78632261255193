import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.HOME.DASHBOARD',
    moduleName: 'dashboard',
    icon: 'fas fa-home',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'home/dashboard/main',
        title: 'GeoHome',
        moduleName: 'dashboard',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MODULES',
    moduleName: '',
    permission: ['READ_C_MST_AGENCY','READ_C_MST_USER','READ_C_MST_CATEGORY','READ_C_MST_ITEM',
    'READ_C_MST_COUNTRY','READ_C_MST_DEPARTMENT','READ_C_MST_CITY','READ_C_MST_COLONY','READ_C_MST_IMAGE',
    'READ_C_MST_FEATURE_GROUP','READ_C_MST_FEATURE','READ_C_MST_LABEL','READ_C_MST_BANK','READ_C_MST_FIELD',
    'READ_C_PRC_CONTACT_ITEM','READ_C_ATTENTION_HOUR','READ_C_BOOKING_REGISTER','READ_C_CHAT',
    'READ_C_ITEM_BOOKING_ITEM','READ_C_PRC_ITEM_BOOKING',
    'READ_NTF_SYS_REMINDER','READ_NTF_SYS_CONFIGURATION',
    'READ_C_RPT_PUBLICATIONS','READ_C_RPT_CONTACTS','READ_C_RPT_MORE_COMUNICATION','MENUITEMS.MAIN.IN_PROCESS',
    'READ_ADDONS_CONTACT_US','READ_ADDONS_WANT_TO_SELL'],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MASTERS',
    moduleName: 'masters',
    permission: ['READ_C_MST_AGENCY','READ_C_MST_USER','READ_C_MST_CATEGORY','READ_C_MST_ITEM',
    'READ_C_MST_COUNTRY','READ_C_MST_DEPARTMENT','READ_C_MST_CITY','READ_C_MST_COLONY','READ_C_MST_IMAGE',
    'READ_C_MST_FEATURE_GROUP','READ_C_MST_FEATURE','READ_C_MST_LABEL','READ_C_MST_BANK','READ_C_MST_FIELD'],
    icon: 'fas fa-tools',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'construction/masters/agencies',
        title: 'MENUITEMS.MAIN.AGENCIES',
        moduleName: 'masters',
        permission: ['READ_C_MST_AGENCY'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'construction/masters/users',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'masters',
        permission: ['READ_C_MST_USER'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.ITEMS',
        moduleName: 'items',
        permission: ['READ_C_MST_CATEGORY','READ_C_MST_ITEM'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/masters/items/categories',
            title: 'MENUITEMS.MAIN.CATEGORIES',
            moduleName: 'items',
            permission: ['READ_C_MST_CATEGORY'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/items/items',
            title: 'MENUITEMS.MAIN.ITEMS',
            moduleName: 'items',
            permission: ['READ_C_MST_ITEM'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.LOCATIONS',
        moduleName: 'locations',
        permission: ['READ_C_MST_COUNTRY','READ_C_MST_DEPARTMENT','READ_C_MST_CITY','READ_C_MST_COLONY'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/masters/locations/countries',
            title: 'MENUITEMS.MAIN.COUNTRIES',
            moduleName: 'locations',
            permission: ['READ_C_MST_COUNTRY'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/locations/departments',
            title: 'MENUITEMS.MAIN.DEPARTMENTS',
            moduleName: 'locations',
            permission: ['READ_C_MST_DEPARTMENT'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/locations/cities',
            title: 'MENUITEMS.MAIN.CITIES',
            moduleName: 'locations',
            permission: ['READ_C_MST_CITY'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/locations/colonies',
            title: 'MENUITEMS.MAIN.COLONIES',
            moduleName: 'locations',
            permission: ['READ_C_MST_COLONY'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: 'construction/masters/galleries',
        title: 'MENUITEMS.MAIN.GALLERIES',
        moduleName: 'masters',
        permission: ['READ_C_MST_IMAGE'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.FEATURES',
        moduleName: 'features',
        permission: ['READ_C_MST_FEATURE_GROUP','READ_C_MST_FEATURE'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/masters/features/feature-groups',
            title: 'MENUITEMS.MAIN.GROUPS',
            moduleName: 'features',
            permission: ['READ_C_MST_FEATURE_GROUP'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/features/features',
            title: 'MENUITEMS.MAIN.FEATURES',
            moduleName: 'features',
            permission: ['READ_C_MST_FEATURE'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: 'construction/masters/labels',
        title: 'MENUITEMS.MAIN.LABELS',
        moduleName: 'masters',
        permission: ['READ_C_MST_LABEL'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.BANK_INFORMATION',
        moduleName: 'banks',
        permission: ['READ_C_MST_BANK','READ_C_MST_FIELD'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/masters/banks/banks',
            title: 'MENUITEMS.MAIN.BANKS',
            moduleName: 'banks',
            permission: ['READ_C_MST_BANK'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/masters/banks/fields',
            title: 'MENUITEMS.MAIN.FIELDS',
            moduleName: 'banks',
            permission: ['READ_C_MST_FIELD'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.PROCESS',
    moduleName: 'process',
    permission: ['READ_C_PRC_CONTACT_ITEM','READ_C_ATTENTION_HOUR','READ_C_BOOKING_REGISTER','READ_C_CHAT',
    'READ_C_ITEM_BOOKING_ITEM','READ_C_PRC_ITEM_BOOKING'],
    icon: 'fas fa-list',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'construction/process/contact-items',
        title: 'MENUITEMS.MAIN.CONTACT_REQUESTS',
        moduleName: 'process',
        permission: ['READ_C_PRC_CONTACT_ITEM'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.BOOKING',
        moduleName: 'booking',
        permission: ['READ_C_ATTENTION_HOUR','READ_C_BOOKING_REGISTER'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/process/booking/contacts',
            title: 'MENUITEMS.MAIN.ATENTION_SCHEDULES',
            moduleName: 'booking',
            permission: ['READ_C_ATTENTION_HOUR'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/process/booking/registers',
            title: 'MENUITEMS.MAIN.RESERVATIONS',
            moduleName: 'booking',
            permission: ['READ_C_BOOKING_REGISTER'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: 'construction/process/chat',
        title: 'MENUITEMS.MAIN.CHAT',
        moduleName: 'process',
        permission: ['READ_C_CHAT'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.ITEMS_FOR_BOOKING',
        moduleName: 'item-booking',
        permission: ['READ_C_ITEM_BOOKING_ITEM','READ_C_PRC_ITEM_BOOKING'],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'construction/process/item-booking/items',
            title: 'MENUITEMS.MAIN.ITEMS',
            moduleName: 'item-booking',
            permission: ['READ_C_ITEM_BOOKING_ITEM'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'construction/process/item-booking/orders',
            title: 'MENUITEMS.MAIN.RESERVATIONS',
            moduleName: 'item-booking',
            permission: ['READ_C_PRC_ITEM_BOOKING'],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.REPORTS',
    moduleName: 'reports',
    permission: ['READ_C_RPT_PUBLICATIONS','READ_C_RPT_CONTACTS','READ_C_RPT_MORE_COMUNICATION','MENUITEMS.MAIN.IN_PROCESS'],
    icon: 'fas fa-file-contract',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'construction/reports/publications',
        title: 'MENUITEMS.MAIN.PUBLICATIONS',
        moduleName: 'reports',
        permission: ['READ_C_RPT_PUBLICATIONS'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'construction/reports/contacts',
        title: 'MENUITEMS.MAIN.BY_CONTACTS',
        moduleName: 'reports',
        permission: ['READ_C_RPT_CONTACTS'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'construction/reports/more-comunication',
        title: 'MENUITEMS.MAIN.MORE_COMUNICATION',
        moduleName: 'reports',
        permission: ['READ_C_RPT_MORE_COMUNICATION'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'construction/reports/in-process',
        title: 'MENUITEMS.MAIN.IN_PROCESS',
        moduleName: 'reports',
        permission: ['READ_C_RPT_WITH_PROCESS'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.CONTACT_US',
    moduleName: 'addons',
    permission: ['READ_ADDONS_CONTACT_US','READ_ADDONS_WANT_TO_SELL'],
    icon: 'fas fa-user-alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'construction/addons/contact-us',
        title: 'MENUITEMS.MAIN.CONTACT_US',
        moduleName: 'reminder',
        permission: ['READ_ADDONS_CONTACT_US'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'construction/addons/want-to-sell',
        title: 'MENUITEMS.MAIN.WANT_TO_SELL',
        moduleName: 'addons',
        permission: ['READ_ADDONS_WANT_TO_SELL'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.NOTIFICATIONS',
    moduleName: 'reminder',
    permission: ['READ_NTF_SYS_REMINDER','READ_NTF_SYS_CONFIGURATION'],
    icon: 'fas fa-bell',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'notification/reminder/reminder',
        title: 'MENUITEMS.MAIN.REMINDERS',
        moduleName: 'reminder',
        permission: ['READ_NTF_SYS_REMINDER'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'notification/reminder/notification-configuration',
        title: 'MENUITEMS.MAIN.CONFIGURATION',
        moduleName: 'reminder',
        permission: ['READ_NTF_SYS_CONFIGURATION'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.SYSTEM',
    moduleName: '',
    permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER',
    'READ_SYS_CNF_COMPANY','READ_SYS_CNF_BANNER','READ_SYS_BLOG','READ_SYS_BENEFIT',
    'READ_SYS_CNF_BANK','READ_SYS_CNF_PAYMENT_METHOD'],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.CONFIGURATION',
    moduleName: 'manage-users',
    permission: ['READ_SYS_CNF_COMPONENT','READ_SYS_CNF_PERMISSION','READ_SYS_CNF_GROUP','READ_SYS_CNF_USER'],
    icon: 'fas fa-cog',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'system/manage-users/components',
        title: 'MENUITEMS.MAIN.COMPONENTS',
        moduleName: 'manage-users',
        permission: ['READ_SYS_CNF_COMPONENT'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/permissions',
        title: 'MENUITEMS.MAIN.PERMISSIONS',
        moduleName: 'manage-users',
        permission: ['READ_SYS_CNF_PERMISSION'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/groups',
        title: 'MENUITEMS.MAIN.GROUPS',
        moduleName: 'manage-users',
        permission: ['READ_SYS_CNF_GROUP'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/users',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'manage-users',
        permission: ['READ_SYS_CNF_USER'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.GENERAL',
    moduleName: 'general',
    permission: ['READ_SYS_CNF_COMPANY','READ_SYS_CNF_BANNER','READ_SYS_BLOG','READ_SYS_BENEFIT'],
    icon: 'fas fa-list',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'system/general/company',
        title: 'MENUITEMS.MAIN.COMPANY',
        moduleName: 'general',
        permission: ['READ_SYS_CNF_COMPANY'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/general/banners',
        title: 'MENUITEMS.MAIN.BANNERS',
        moduleName: 'general',
        permission: ['READ_SYS_CNF_BANNER'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/general/blog',
        title: 'MENUITEMS.MAIN.BLOG',
        moduleName: 'general',
        permission: ['READ_SYS_BLOG'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/general/benefits',
        title: 'MENUITEMS.MAIN.BENEFITS',
        moduleName: 'general',
        permission: ['READ_SYS_BENEFIT'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.PAYMENTS',
    moduleName: 'payments',
    permission: ['READ_SYS_CNF_BANK','READ_SYS_CNF_PAYMENT_METHOD'],
    icon: 'fas fa-credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'system/payments/payment-banks',
        title: 'MENUITEMS.MAIN.BANKS',
        moduleName: 'payments',
        permission: ['READ_SYS_CNF_BANK'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/payments/payment-methods',
        title: 'MENUITEMS.MAIN.PAYMENT_METHODS',
        moduleName: 'payments',
        permission: ['READ_SYS_CNF_PAYMENT_METHOD'],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
];
