// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //domain: 'http://localhost:3000/',
  domain: 'https://geo.albatrosvirtual.com:81/', // PRD
  //domain: 'https://geo.albatrosvirtual.com:91/', // QAS
  url_amazon_s3: 'https://geodevfiles.s3.eu-central-1.amazonaws.com/folder',

  domainSocket: 'http://geo.albatrosvirtual.com:8081', // PRD
  //domainSocket: 'http://geo.albatrosvirtual.com:8091', // QAS
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
